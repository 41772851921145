@media screen and (min-width: 601px){
      .search-resp{
        max-width: 100%;
        margin: 0 auto;
      }
      .topSpacing {
        margin-top: 0.6vh;
      }
    }

    @media screen and (max-width: 600px){
        .search-resp{
            max-width: 100%;
            margin: 0 auto;
        }
        .topSpacing {
          margin-top: 8vh;
        }
      }
