.blackBar {
  background-color:#000;
  margin-top:'-5.25vw';
  padding:2vw;
  height:55vw;
}

.buttonStyle {
  display: inline-block;
  vertical-align: middle;
}

.curp {
  cursor: pointer;
}

.inputStyle {
  border: none;
  border-radius: 2px;
  padding: 8px;
  cursor: pointer;
}

@media screen and (min-width: 601px){
  .column1 {
    float: left;
    width: 30%;
  }
  
  /* Clear floats after the columns */
  .row2:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .blackBar {
    background-color:#fff;
    margin-top:0vw;
    padding:2vw;
    height:30vh;
  }
  
  .mobHead21 {
    padding-left: 0vw;
    margin-top: 6vh;
    margin-bottom: 0px;
    font-size: 6vh;
  }
  
  .mobHead22 {
    padding-bottom: 1vh;
    padding-top: 0px;
    padding-left: 0vw;
    margin-top: 0vh;
    margin-bottom: 0px;
    font-size: 4vh;
  }
  
  .subBank {
    margin-bottom: 0px;
    margin-top: 4vh;
  }
  
  .inputStyle {
    font-size: medium;
  }

  .mobMainImg {
    content: url("https://i.ibb.co/52mvHqT/noerror.png");
  }

  .noError {
    top:28vh;
    height:100%;
    width:100%;
    display:block;
    position:absolute;
    text-align:center;
    font-size:8vh;
    color:black;
  }

  .subError {
    top:40vh;
    height:100%;
    width:100%;
    display:block;
    position:absolute;
    text-align:center;
    font-size:small;
    color:black;
    padding-left: 10vw;
    padding-right: 10vw;
    font-size: 4vh;
  }

  .marginR {
    margin-right: 10px;
  }

  .mobText1 {
    font-size: xx-small;
  }
  .mobText15 {
    font-size: x-small;
  }

  .mobText2 {
    font-size: medium;
  }

  .mobText3 {
    font-size: 3vh;
    letter-spacing: 0px;
  }

  .categoryDiv{
    min-height:0; 
    display:grid;
    grid-template-rows:1%;
    grid-template-columns: repeat(3, 1fr);
    margin-left:1vw;
    margin-right:1vw;
    padding:1vw;
    margin-bottom:-2vw;
  }

  .categDesign {
    max-width: 100%;
    height: 100%;
    padding: 20px;
  }

  .dowbBox {
    margin-top: 60vw;
  }

  .videoFrame {
    width:100%;
    height:72vh;
    position:relative;
    background-size: cover;
    background-position: center;
  }
  
  /* Style the header */
  .header {
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 1%;
      padding-bottom: 1%;
      background: #fff;
      color: #000;
      position: fixed;
      right: 0;
      left: 0;
      width: 100%;
      z-index: 999;
      align-items: center;
      align-content: center;
      justify-items: center;
      justify-content: center;
    }

  .headerText {
    margin-top: 0px;
    margin-bottom:8px;
    display:inline-block;
    font-family:Outfit;
    font-size:8vh;
  }

  .headerButtons {
    float: right;
    width: auto;
    vertical-align: center;
    margin-top: 1vh;
  }

  .mobMarg {
    margin-right: 300px;
  }
    
  /* Style the header */
  .footerGlia { 
    display: flex;
    bottom: 0px;   
    width: 100%;  
    background-color: rgb(0, 0, 0);  
    color: white;  
    height: 12vw;
    align-items: center;
  }

  .topMidBut{
    top: 60vh;
  }

    /* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */

    .animated-gif-container { 
      background-image:url(http://winnerhun.uw.hu/vigyorpofa.gif);
      background-repeat:no-repeat;
      width:48px;
      height:48px;    
      padding-left: 20px; 
    }

  .gliaButton {
      float: right;
      margin: 0px;
      margin-right: 0px;
      position: absolute;
      top: 50%;
      right: 5%;
      text-align: right;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      background: #fff;
      color: #000;
  }

  .imgResponsive {
      width:100%;
      height: 100%;
  }

  .overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.75); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  }

  .responsive {
    width: 100%;
    height: auto;
    justify-self: center;
  }

  .responsive2 {
    max-width: 100%;
    max-height: 100%;
    justify-self: center;
    border: 0px;
    height: 70vh;
  }

  .responsive3 {
    width: 100%;
    max-height: 100%;
    justify-self: center;
    display: cover;
    border: 0px;
  }

  .buttons {
    margin-left: 1%;
    margin-right: 1%;
  }

  .descript {
    text-align: center;
    font-family: 'Bowlby One';
    font-size: 6vh;
    margin-top: -3.0vw;
  }

  .explain {
    text-align: center;
    font-family: 'Outfit';
    font-size: 2vh;
    margin-top: -2.5vw;
  }

  .page-container{
    position: relative;
    min-height: 100vh;
    max-height: 100%;
  }

  .blueButton{
    border-radius:2px;
    color:black;
    justify-self:center;
    border:none;
    background-color:#FBDD02;
    justify-content:center;
    padding: 2px; 
    margin-bottom:7.5%;
  }
}

@media screen and (max-width: 600px){
  .mobHead21 {
    padding-left: 0vw;
    margin-top: 6vh;
    margin-bottom: 0vh;
    font-size: 6vw;
  }
  
  .mobHead22 {
    padding-bottom: 2vh;
    padding-top: 0vh;
    padding-left: 0vw;
    margin-bottom: 0;
    font-size: 6vw;
  }
  
  .subBank {
    margin-bottom: 30px;
  }
  
  .inputStyle {
    font-size: 2vh;
  }

  .mobMainImg {
    content: url("https://i.ibb.co/52mvHqT/noerror.png");
  }

  .noError {
    top:31vh;
    height:100%;
    width:100%;
    display:block;
    position:absolute;
    text-align:center;
    font-size:6vh;
    color:black;
  }

  .subError {
    top:56vh;
    height:100%;
    width:100%;
    display:block;
    position:absolute;
    text-align:center;
    font-size:small;
    color:black;
    padding-left: 10vw;
    padding-right: 10vw;
    font-size: 3vh;
  }

  .exampleDeal {

  }

  .marginR {
    margin-right: 10px;
  }

  .mobText1 {
    font-size: xx-small;
  }
  .mobText15 {
    font-size: x-small;
  }

  .mobText2 {
    font-size: 3vh;
  }

  .mobText3 {
    font-size: 4vw;
    letter-spacing: 2px;
  }

  .categoryDiv{
    min-height:0;
    margin-left:1vw;
    margin-right:1vw;
    padding:4.5vw;
    margin-bottom:3vw;
  }

  .dowbBox {
    margin-top: 60vw;
  }

  .videoFrame {
    width:100%;
    height:100vh;
    position:relative;
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }
  
  /* Style the header */
  .header {
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 2%;
      padding-bottom: 5%;
      background: #fff;
      color: #000;
      position: fixed;
      right: 0;
      left: 0;
      width: 100%;
      z-index: 999;
      align-items: center;
      align-content: center;
      justify-items: center;
      justify-content: center;
    }

  .headerText {
    margin-top:auto;
    padding-top: 1vh;
    margin-bottom:auto;
    display:inline-block;
    font-family:Outfit;
    font-size:5vh;
    margin-left: 0vw;
  }

  .headerButtons {
    float: right;
    width: auto;
    margin-top: 3.5vw;
  }

  .mobMarg {
    margin-right: 300px;
  }
    
  /* Style the header */
  .footerGlia { 
    display: flex;
    bottom: 0px;   
    width: 100%;  
    background-color: rgb(0, 0, 0);  
    color: white;  
    height: 12vw;
    align-items: center;
  }

  .topMidBut{
    top: 83vh;
  }

    /* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */

    .animated-gif-container { 
      background-image:url(http://winnerhun.uw.hu/vigyorpofa.gif);
      background-repeat:no-repeat;
      width:48px;
      height:48px;    
      padding-left: 20px; 
    }

  .gliaButton {
      float: right;
      margin: 0px;
      margin-right: 0px;
      position: absolute;
      top: 50%;
      right: 5%;
      text-align: right;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      background: #fff;
      color: #000;
  }

  .imgResponsive {
      width:100%;
      height: 100%;
  }

  .overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.75); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  }

  .responsive {
    width: 100%;
    height: auto;
    justify-self: center;
  }

  .responsive2 {
    max-width: 100%;
    max-height: 100%;
    justify-self: center;
    display: cover;
    border: 0px;
    height: 110vw;
  }

  .responsive3 {
    max-width: 100%;
    max-height: 150%;
    justify-self: center;
    white-space: nowrap;
    overflow: hidden;
    display: cover;
  }

  .buttons {
    margin-left: 1%;
    margin-right: 1%;
  }

  .descript {
    text-align: center;
    font-family: 'Bowlby One';
    font-size: 10.0vw;
    margin-top: -8.0vw;
  }

  .explain {
    text-align: center;
    font-family: 'Outfit';
    font-size: small;
    margin-top: -6.5vw;
  }

  .page-container{
    position: relative;
    min-height: 100vh;
    max-height: 100%;
  }

  .blueButton{
    border-radius:2px;
    font-size:small;
    color:white;
    justify-self:center;
    border:none;
    background-color:#FBDD02;
    justify-content:center;
    padding:10px; 
    margin-bottom:7.5%;
  }
}