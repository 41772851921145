

  @media screen and (min-width: 601px){
    .site-container-test{
      flex-direction: row;
      margin: 1em;
      padding: 4px;
      max-width: 30%;
      height: auto;
      border: 1px;
    }
    .landing-page-style{
      flex-direction: row;
      margin: 1em;
      padding: 4px;
      height: auto;
      border: 1px;
      margin:6vh auto 0vh auto;
      max-width:35%;
      height:82vh;
    }
      .header-style{
        color:black;
        font-size:6vh;
        margin: auto;
      }
      .head-container-test{
        margin-right: auto;
        margin-left: 5%;
        align-self: center;
        cursor: pointer;
        color: black;
      }
      .head-container-test2{
        margin-right: auto;
        margin-left: auto;
        align-self: center;
      }
      .head-container-test3{
        margin-right: 5%;
        margin-left: auto;
        align-self: center;
      }
    }

@media screen and (max-width: 600px){
        .site-container-test{
          flex-direction: row;
          margin: 1.5em 0vw 1em 0vw;
          padding: 0px;
          width: 85%;
          height: auto;
          border: 0px;
        }

        .header-style{
          color:black;
          font-size:6vh;
          margin: auto;
        }
        .head-container-test{
          margin-right: auto;
          margin-left: auto;
          align-self: center;
          cursor: pointer;
          color: black;
        }
        .landing-page-style{
          flex-direction: row;
          margin: 1em;
          padding: 4px;
          height: auto;
          border: 1px;
          margin:6vh auto 0vh auto;
          max-width:85%;
          height:82vh;
        }
        .head-container-test2{
          margin-right: auto;
          margin-left: auto;
          align-self: center;
        }
        .head-container-test3{
          margin-right: 5%;
          margin-left: auto;
          align-self: center;
        }

     
      }